import NavbarKinya from "../../components/kinya/navbarKinya";
import AboutUsKinya from "../../components/kinya/aboutus/aboutusKinya";

export default function GetInvolvedKinya() {
  return (
    <div>
      <NavbarKinya />
      <AboutUsKinya />
    </div>
  );
}
